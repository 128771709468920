import { Box } from 'components/Box/Box';
import { RichTextHeadline } from 'components/Text/RichTextHeadline';
import { CmsApiPage } from 'modules/cms/api/types/CmsApiPage';
import { CmsApiCategoryHighlightBlockData } from 'modules/cms/api/types/blocks/CmsApiCategoryHighlightBlock';
import { gutterWidth } from 'theme/theme';

import { CmsCategoryHighlightBlockCategory } from './CmsCategoryHighlightBlockCategory';

type Props = {
  content: CmsApiPage;
  data: CmsApiCategoryHighlightBlockData;
  blockIndex: number;
  pageUpdated: string;
};

export function CmsCategoryHighlightBlock({
  content,
  data: { headline, categories },
  blockIndex,
  pageUpdated,
}: Props) {
  const homePageUrl = content.subsite.homePage?.url;

  return (
    <Box data-qa-id="cms-category-highlight">
      {headline && (
        <Box
          textAlign={['left', 'center']}
          mb={[gutterWidth / 2, null, null, gutterWidth * 1.5]}
        >
          <RichTextHeadline>{headline}</RichTextHeadline>
        </Box>
      )}

      <Box
        m={[-gutterWidth / 4, null, -gutterWidth / 2]}
        display="flex"
        flexWrap="wrap"
      >
        {categories.map((category, index) => (
          <CmsCategoryHighlightBlockCategory
            category={category}
            homePageUrl={homePageUrl}
            key={`${category.name}-${index}`} // eslint-disable-line react/no-array-index-key
            blockIndex={blockIndex}
            pageUpdated={pageUpdated}
          />
        ))}
      </Box>
    </Box>
  );
}
