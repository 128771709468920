import styled from 'styled-components';

import { Box } from 'components/Box/Box';
import { Divider } from 'components/Divider/Divider';
import { FilestackImage } from 'components/FilestackImage/FilestackImage';
import { Icon } from 'components/Icon/Icon';
import { StyledNativeLinkWithRef } from 'components/Link/StyledNativeLinkWithRef';
import { BodySmall } from 'components/Text/BodySmall';
import { H3 } from 'components/Text/H3';
import { CmsApiCategoryHighlightBlockItem } from 'modules/cms/api/types/blocks/CmsApiCategoryHighlightBlock';
import { colors, gutterWidth, transition } from 'theme/theme';
import { trackClicked } from 'utils/analytics/track/trackClicked';

const ImageContainer = styled(Box)`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`;

const StyledLink = styled(StyledNativeLinkWithRef)<{ $color?: string }>`
  color: ${(props) => props.$color};

  svg {
    transition: transform ${transition};

    path,
    polyline {
      fill: currentcolor;
    }
  }

  &:hover,
  &:focus {
    &,
    * {
      color: ${(props) => props.$color};
      text-decoration: underline;
    }

    svg {
      transform: translateX(4px);
    }
  }
`;

type Props = {
  homePageUrl: string | undefined;
  category: CmsApiCategoryHighlightBlockItem;
  blockIndex: number;
  pageUpdated: string;
};

export function CmsCategoryHighlightBlockCategory({
  category,
  homePageUrl,
  blockIndex,
  pageUpdated,
  ...props
}: Props) {
  return (
    <Box
      data-qa-id={`category-highlight-${category.slug}`}
      p={[gutterWidth / 4, null, gutterWidth / 2]}
      width={[1, 1, 1 / 3]}
      flex="0 0 auto"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      {category.image ? (
        <Box position="relative" height={96} width={96} mb="24px">
          <ImageContainer>
            <FilestackImage
              alt={category.image.altText || ''}
              data={category.image}
              data-qa-id={`image-${category.slug}`}
            />
          </ImageContainer>
        </Box>
      ) : null}
      <H3 color={colors.lightContentGrey} my="16px">
        {category.name}
      </H3>

      <BodySmall lineHeight="1.25">{category.description}</BodySmall>

      <Divider marginBottom={24} marginTop={16} />

      {category.pages.map((page, index) => {
        const pageTitleWords = page.title.split(' ');
        return (
          <BodySmall
            styleAs="h4"
            lineHeight="1.25"
            my="16px"
            key={page.id || index}
          >
            <StyledLink
              data-qa-id="articles-link"
              href={`/${page.url}`}
              onClick={() => {
                trackClicked('CMS Block', {
                  block_position: blockIndex,
                  block_type: 'Category Highlight',
                  cta_type: 'Link',
                  page_updated: pageUpdated,
                });
              }}
            >
              {pageTitleWords.slice(0, pageTitleWords.length - 1).join(' ')}{' '}
              <Box display="inline-flex">
                {pageTitleWords
                  .slice(pageTitleWords.length - 1, pageTitleWords.length)
                  .join(' ')}

                <Box display="inline-flex" ml="8px">
                  <Icon name="arrow-right" size={24} />
                </Box>
              </Box>
            </StyledLink>
          </BodySmall>
        );
      })}
      {homePageUrl && (
        <BodySmall styleAs="h5" fontSize={20} lineHeight="1.25" mb="8px">
          <StyledLink
            $color={colors.lightContentGrey}
            data-qa-id={`articles-link-${category.slug}`}
            href={`/${homePageUrl}?categories=${category.slug}&pq=`}
            onClick={() => {
              trackClicked('CMS Block', {
                block_position: blockIndex,
                block_type: 'Category Highlight',
                cta_type: 'Link',
                page_updated: pageUpdated,
              });
            }}
          >
            <Box component="span" display="inline-flex">
              {getText('See all')}

              <Box ml="8px" display="inline-flex">
                <Icon name="arrow-right" size={24} />
              </Box>
            </Box>
          </StyledLink>
        </BodySmall>
      )}
    </Box>
  );
}
